import React from 'react'
import { Link } from 'gatsby' 
import Logo from "../images/logo.png"

import "../scss/main.scss"

const Thanks = () => {
  return (
    <section className="thanks">
      <img className="thanks__logo" src={Logo} alt="AF First Aid" />
      <p className="thanks__content">Thanks for submitting. We will get back to you as soon as we can.</p>
      {/* <a className="thanks__button">Download Brochure</a> */}
      <Link to="/">Return to site</Link>
    </section>
  )
}

export default Thanks